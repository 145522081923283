.tweet-box
    &__tweet
        background: $twitter-blue !important
        border-radius: 50px !important
        box-shadow: none !important
        font-size: 17px !important
        font-weight: 700 !important
        min-height: 44px
        text-transform: capitalize !important

        &--disabled
            opacity: 0.5
            cursor: default !important

.tweet-editor
    &__textarea
        font-size: 20px
        line-height: 1.3
        left: 48px
        width: calc(100% - 48px)
        resize: none

    &__dummy
        font-size: 20px
        line-height: 1.3
        min-height: 60px
