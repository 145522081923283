$icon-size: 26.5px

.sidebar
    height: 100vh

    &__wrapper
        width: 275px

    &__container
        width: 275px

    &__item
        @apply cursor-pointer
        border-radius: 50px
        font-size: 32px
        width: unset
        transition: background-color 0.3s ease

        &.active
            font-weight: bold

        @media (hover: hover)
            &:hover
                background: rgba(15, 20, 25, 0.1)

        &-label
            font-size: 20px

        &--tw
            height: 50px
            width: 50px

    &__icon
        text-align: center
        min-width: $icon-size


    &__tweet-btn
        background: $twitter-blue !important
        border-radius: 50px !important
        box-shadow: none !important
        font-size: 17px !important
        font-weight: 700 !important
        min-height: 52px
        text-transform: capitalize !important
        width: 90%

