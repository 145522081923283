@config "../../tailwind.config.js"

@tailwind base
@tailwind components
@tailwind utilities

@layer components
    @import "variables/colours"
    @import "components/app"
    @import "components/sidebar"
    @import "components/timeline"
    @import "components/widget"

    @import "components/tweetbox"
    @import "components/post"
    @import "components/search"
