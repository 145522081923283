.widget
    width: 350px

    .get-verified
        &__button
            @apply rounded-full capitalize font-bold flex items-center

            background: #000 !important
            box-shadow: none
            height: 36px
            line-height: 1 !important

            @media (hover: hover)
                box-shadow: none !important

    .trend
        &-feature
            &__image
                height: 68px
                width: 68px
